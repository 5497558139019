import { FC } from 'react'
import { SVGProps } from '../Icon'

const Eye: FC<SVGProps> = ({ className }) => (
    <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M15.6822 7.54037C13.9894 3.9743 11.4305 2.17966 8.0001 2.17966C4.56796 2.17966 2.01082 3.9743 0.31796 7.54216C0.250059 7.68594 0.214844 7.84297 0.214844 8.00198C0.214844 8.16098 0.250059 8.31802 0.31796 8.4618C2.01082 12.0279 4.56975 13.8225 8.0001 13.8225C11.4322 13.8225 13.9894 12.0279 15.6822 8.46001C15.8197 8.17073 15.8197 7.83501 15.6822 7.54037ZM8.0001 12.5368C5.11975 12.5368 3.01082 11.0761 1.52332 8.00109C3.01082 4.92609 5.11975 3.46537 8.0001 3.46537C10.8805 3.46537 12.9894 4.92609 14.4769 8.00109C12.9912 11.0761 10.8822 12.5368 8.0001 12.5368ZM7.92868 4.85823C6.19296 4.85823 4.78582 6.26537 4.78582 8.00109C4.78582 9.7368 6.19296 11.1439 7.92868 11.1439C9.66439 11.1439 11.0715 9.7368 11.0715 8.00109C11.0715 6.26537 9.66439 4.85823 7.92868 4.85823ZM7.92868 10.0011C6.82332 10.0011 5.92868 9.10644 5.92868 8.00109C5.92868 6.89573 6.82332 6.00109 7.92868 6.00109C9.03403 6.00109 9.92868 6.89573 9.92868 8.00109C9.92868 9.10644 9.03403 10.0011 7.92868 10.0011Z" />
    </svg>
)

export default Eye
